@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** Rewrite default toast styles **/

div.Toastify__toast-container {
  padding: 0;
}

div.Toastify__toast {
  padding: 0;
}

div.Toastify__toast-body {
  align-items: unset;
  padding: 0;
  margin: 0;
}

div.Toastify__toast-icon {
  width: 40px;
  margin-left: 8px;
}

button.Toastify__close-button {
  align-self: auto;
  padding: 0 2px 0 2px;
  margin-right: 18px;
}
