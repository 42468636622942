.ql-bubble .ql-tooltip {
  z-index: 10;
}
.ql-container {
  font-size: 14pt;
}
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='12pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='12pt']::before {
  content: '12';
}

.ql-bubble .ql-picker.ql-size .ql-picker-label::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item::before {
  content: '14';
}
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='16pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='16pt']::before {
  content: '16';
}
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='18pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='18pt']::before {
  content: '18';
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='20pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='20pt']::before {
  content: '20';
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='24pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='24pt']::before {
  content: '24';
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='48pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='48pt']::before {
  content: '48';
}

.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value='64pt']::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value='64pt']::before {
  content: '64';
}
